<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { breadcrumbs } = useBreadcrumbs();
const router = useRouter();
const { locale } = useI18n();

const goToPreviousPage = () => {
  // router.back();
  /**
   * the arrow must always point to the next higher level
   */
  if (breadcrumbs.value && breadcrumbs.value.length > 1) {
    const previousBreadcrumb = breadcrumbs.value[BreadcrumbsLength.value - 1];

    if (previousBreadcrumb.path === '/') {
      previousBreadcrumb.path = '';
    }

    router.push('/' + locale.value + '/' + previousBreadcrumb.path);
  }
};

const BreadcrumbsLength = computed(() => {
  if (breadcrumbs && breadcrumbs.value) {
    return breadcrumbs.value.length - 1;
  }
  return 0;
});
</script>

<template>
  <Breadcrumbs :breadcrumbs="breadcrumbs" :separator="false">
    <template #topNavigationItemMobile>
      <Bar
        v-if="breadcrumbs.length && breadcrumbs[BreadcrumbsLength]"
        :title="breadcrumbs[BreadcrumbsLength]?.name || ''"
        back-button
        @click="goToPreviousPage"
      />
    </template>
    <template #topNavigationItem>
      <template v-for="(breadcrumb, index) in breadcrumbs" :key="index">
        <span v-if="!breadcrumb.path" class="breadcrumbs_link no-link">{{
          breadcrumb.name
        }}</span>
        <LocaleRouterLink
          v-else
          :to="
            breadcrumb.path === '/' ? breadcrumb.path : `/${breadcrumb.path}`
          "
          class="breadcrumbs_link"
          :class="{
            'breadcrumbs__link-active':
              breadcrumbs[BreadcrumbsLength]?.path === breadcrumb.path,
          }"
        >
          {{ breadcrumb.name }}
        </LocaleRouterLink>
      </template>
    </template>
  </Breadcrumbs>
</template>
